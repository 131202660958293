.blog-wrapper {
    padding: 120px 0;
}

.single-blog-card {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 5.288px 11.876px 40px 0 #0000000d;
    display: inline-block;
    margin-top: 40px;
    overflow: hidden;
}

.single-blog-card:hover, .card-thumb:hover {
    background-color: #FFE5EC;
    color: #fff;
}

.card-thumb {
    background-color: #eee;
    width: 100%;
    height: 297px;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-thumb img {
    width: 100%;
    height: 100%;
}

.card-content {
    padding: 40px;
}

.card-content h3 {
    font-family: "Josefin Sans",sans-serif;
    font-weight: 500!important;
    border-bottom: 2px solid hsla(0,0%,86.3%,.49);
    padding-bottom: 25px;
    margin-bottom: 10px;
    font-size: 26px;
}

.card-meta span {
    font-weight: 300;
    font-size: 16px;
}