.about-wrapper {
    /* margin-bottom: 120px; */
    height: auto;
    background-color: #9BC1BC;
    padding: 30px 0px
}

.about-left {
    position: relative;
    width:300px
}

.animated {
    left: 35px;
    animation: movebounce 2.5s ease-in-out infinite;
    animation-delay: 100ms;
    animation-direction: alternate;
    bottom: 0px;
    width:500px
}

.about-content h1 {
    /* margin-bottom: 20px; */
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    padding:50px
}

.about-content p {
    color: #666;
}

.about-content a, .single-service-box a {
    margin-top: 17px;
    color: #FFE5EC;
    font-size: 16px;
    text-decoration: none;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 700;
    padding-bottom: 4px;
    display: inline-block;
    border-bottom: 2px solid #FFE5EC;
    transition: all 0.4s ease 0s;
}

.about-content a:hover {
    color: #000;
}

.fun-fact-sec {
    background-color: #ffffff;
    box-shadow: #00000014 8.135px 18.271px 40px 0px;
    margin-top: 50px;
    position: relative;
    height: 100%;
    padding: 65px 80px 21px;
    overflow: hidden;
}

.single-fun {
    position: relative;
    width: 50%;
    margin-bottom: 45px;
    float: left; 
    text-align: start;
}

.sp-fun {
    padding-left: 60px;
}

.single-fun span {
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    position: relative;
}

.single-fun p {
    color: #666;
    font-weight: 300;
    line-height: 1;
    margin-top: 8px;
}

.line {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 1px;
    height: 70%;
    background: #dadada;
    content: "";
}
/* for mobile responsive */
@media (max-width: 992px) {
    .about-left {
        margin-top: 40px;
        width:auto;
    }
    .mt-5{
        margin-top: 0px;   
    }
    .about-right{
        margin-top: 0px;   
    }
    .about-content h1 {
        /* margin-bottom: 20px; */
        font-family: "Josefin Sans", sans-serif;
        font-weight: 600;
        padding:10px
    }

}

/* for mobile responsive */
@media only screen and (max-width: 426px){
    .about-wrapper {
        /* padding: 25px; */
        margin-bottom: 120px;
        height: 400px;
        align-items: center;
    }

    .animated {
        left: 100px;
        animation: movebounce 2.5s ease-in-out infinite;
        animation-delay: 100ms;
        animation-direction: alternate;
        bottom: 0px;
    }

    .dontoAnim {
        max-width: 50%;
        align-items: center;
    }
    .about-content a, .single-service-box a {
        margin-top: 10px;
        color: #FFE5EC;
        font-size: 16px;
        text-decoration: none;
        line-height: 24px;
        text-transform: capitalize;
        font-weight: 700;
        border-bottom: 2px solid #FFE5EC;
        transition: all 0.4s ease 0s;
    }
    .about-content h1{
        text-align: center;
    }
    .about-content p{
        text-align: center;
    }
    .text-start a{
        text-align: center;
    }
    
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .card {
      background: #bada55;
    }
    .about-left {
        margin-top: 45px;
        width: auto;
    }
  }